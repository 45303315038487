<template>
  <button 
    class="btn-success"
    @click="$emit('on:click')"
    >Agregar</button>
</template>

<script>
export default {

}
</script>

<style leng="scss" scoped>

button{
    width:120px;
    height:40px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    border:none;
}

</style>